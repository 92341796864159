import Vue from 'vue'
import Vuex from 'vuex';
import VueRouter, { RouteConfig } from 'vue-router'
import Store from "@/store/index"
import {Watch} from "vue-property-decorator";

Vue.use(VueRouter)

// @ts-ignore
let menu_list = Store.state.menu.menuArr
// @ts-ignore
@Watch(Store.state.menu.menuArr)
  function getMenu(oldValue,newValue){
}

const routes: Array<RouteConfig> = [
  { // 首次加载页
    path:'/',
    redirect:localStorage.getItem('userInfo') ? localStorage.getItem('activePath') : '/login'
  },
  { // 登录
    path:'/login',
    name:'登录',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
  },
  { // 主页
    path:'/home',
    // redirect:localStorage.getItem('userInfo') ? localStorage.getItem('activePath') : '/home',
    name:'主页',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
    children:[
      { // 站点管理
          path: '/serviceStation',
          name:'站点管理',
          component: () => import('@/views/stationManage/index.vue'),
          children:[]
      },
      { // 站点安装记录
        path:'/serviceStation/installRecords',
        name:'站点安装记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/stationManage/installRecords.vue'),
      },
      { // 站点检测记录
        path:'/serviceStation/detectionRecords',
        name:'站点检测记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/stationManage/detectionRecords/index.vue'),
      },
      { // 补充站点检测记录
        path:'/serviceStation/detectionRecords/edit',
        name:'补充站点检测记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/stationManage/detectionRecords/Edit.vue'),
      },
      { // 打印检测结果
        path:'/serviceStation/detectionRecords/print',
        name:'打印检测结果',
        component: () => import(/* webpackChunkName: "about" */ '@/views/stationManage/detectionRecords/print.vue'),
      },
      { // 车载设备通讯记录
        path:'/carManage/communication',
        name:'车载设备通讯记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/carManage/communication.vue'),
      },
      { // 检测记录
        path:'/carManage/detectionList',
        name:'检测记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/carManage/detectionList.vue'),
      },
      { // 车主管理
          path: '/eBikeuser',
          name:'车主管理',
          component: () => import('@/views/ownerManage/index.vue'),
          children:[]
      },
      { // 卡用户列表
        path: '/ownerManage/cardUserList',
        name:'卡用户列表',
        component: () => import('@/views/ownerManage/cardUser/index.vue'),
        children:[]
      },
      { // 充电用户列表
        path: '/ownerManage/chargePileUserList',
        name:'充电用户列表',
        component: () => import('@/views/ownerManage/chargePileUser/index.vue'),
        children:[]
      },
      { // 异常记录
          path: '/abnormalRecord',
          name:'异常记录',
          component: () => import('@/views/abnormalRecords/index.vue'),
          children:[]
      },
      { // 小区管理
          path: '/unitSystem',
          name:'小区管理',
          component: () => import('@/views/neighbourhoodManage/index.vue'),
          children:[]
      },
      { // 停车区列表
        path:'/neighbourhoodManage/parkList',
        name:'停车区列表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/neighbourhoodManage/parkList/index.vue'),
      },
      { // 审核列表
        path:'/neighbourhoodManage/examineList',
        name:'审核列表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/neighbourhoodManage/examineList.vue'),
      },
      { // 版本管理
          path: '/version',
          name:'版本管理',
          component: () => import( '@/views/versionManage/index.vue'),
          children:[]
      },
      { // 车辆管理
          path: '/device/eBike',
          name:'车辆管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/carManage/index.vue'),
      },
      { // 车载设备通讯记录
        path:'/carManage/communication',
        name:'车辆通讯记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/carManage/communication.vue'),
      },
      { // 车载设备管理
        path: '/device/carDevice',
        name:'车载设备管理',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/carDeviceManage/index.vue'),
      },
      { // 车载设备管理
        path: '/device/carDevice/communication',
        name:'车载设备通讯记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/carDeviceManage/communication.vue'),
      },
      { // 感应器管理
          path: '/device/inductor',
          name:'感应器管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/inductorManage/index.vue'),
      },
      { // 开关管理
          path: '/device/switch',
          name:'开关管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/switchManage/index.vue'),
      },
      { // 感应器设备通讯记录
        path:'/inductorManage/communication',
        name:'感应器通讯记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/inductorManage/communication.vue'),
      },
      { // 感应器设备通讯记录
        path:'/inductorManage/reactionRecords',
        name:'感应器感应记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/inductorManage/reactionRecords.vue'),
      },
      { // 感应器设备通讯记录
        path:'/inductorManage/switchCommuication',
        name:'4G开关通讯记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/inductorManage/switchCommuication.vue'),
      },
      { // 道闸管理
          path: '/device/barrierGate',
          name:'道闸管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/brakeManage/index.vue'),
      },
      { // 道闸通讯记录
        path:'/brakeManage/communication',
        name:'道闸通讯记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/brakeManage/communication.vue'),
      },
      { // 道闸通行记录
        path: '/device/brakeManage/through',
        name:'道闸通行记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/brakeManage/throughRecords.vue'),
      },
      { // 红外头盔识别管理
        path: '/device/infraredHelmet',
        name:'红外头盔识别管理',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/infraredHelmetManage/index.vue'),
      },
      { // 红外头盔通讯记录
        path:'/device/infraredHelmet/communication',
        name:'红外头盔通讯记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/infraredHelmetManage/communication.vue'),
      },
      { // 红外头盔通行记录
        path: '/device/infraredHelmet/through',
        name:'红外头盔通行记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/infraredHelmetManage/throughRecords.vue'),
      },
      { // 路由器管理
        path: '/device/router',
        name:'路由器管理',
        component: () => import( '@/views/equipManage/routerManage/index.vue'),
      },
      { // WiFi开关通讯记录
        path: '/device/wifiCommunication',
        name:'WIFI开关通讯记录',
        component: () => import( '@/views/equipManage/routerManage/wifiCommunication.vue'),
      },
      { // 地锁管理
        path: '/device/floorLock',
        name:'地锁管理',
        component: () => import( '@/views/equipManage/floorLockManage/index.vue'),
      },
      { // 地锁通讯记录
        path: '/device/floorLock/communication',
        name:'地锁通讯记录',
        component: () => import( '@/views/equipManage/floorLockManage/communication.vue'),
      },
      { // 地锁停放记录
        path: '/device/floorLock/parkRecords',
        name:'地锁停放记录',
        component: () => import( '@/views/equipManage/floorLockManage/parkRecords.vue'),
      },
      { // 地锁预约记录
        path:'/device/floorLock/appointRecords',
        name:'地锁预约记录',
        component: () => import( '@/views/equipManage/floorLockManage/appointRecords.vue'),
      },
      { // 新能源充电桩管理
        path: '/device/chargePile',
        name:'新能源充电桩管理',
        component: () => import( '@/views/equipManage/chargePileManage/index.vue'),
      },
      { // 电瓶车充电桩管理
        path: '/device/batteryChargePile',
        name:'电瓶车充电桩管理',
        component: () => import( '@/views/equipManage/batteryChargePileManage/index.vue'),
      },
      { // 检测房管理
        path: '/device/detectionHouse',
        name:'检测房管理',
        component: () => import( '@/views/equipManage/detectionHouseManage/index.vue'),
      },
      { // 充放电一体柜管理
        path: '/device/chargeDischarge',
        name:'充放电一体柜管理',
        component: () => import( '@/views/equipManage/chargeDischargeManage/index.vue'),
      },
      { // 新能源充电订单列表
        path: '/orderManage/orderList',
        name:'新能源车充电订单列表',
        component: () => import( '@/views/orderManage/orderList/index.vue'),
      },
      { // 电瓶车充电订单列表
        path: '/orderManage/batteryOrderList',
        name:'电瓶车充电订单列表',
        component: () => import( '@/views/orderManage/batteryOrderList/index.vue'),
      },
      { // 充放电柜订单列表
        path: '/orderManage/chargeDischargeOrderList',
        name:'充放电柜订单列表',
        component: () => import( '@/views/orderManage/chargeDischargeOrderList/index.vue'),
      },
      { // 发票申请
        path: '/orderManage/invoiceApplyList',
        name:'发票申请',
        component: () => import( '@/views/orderManage/invoiceApply/index.vue'),
      },
      { // 商户管理
        path: '/commercialOwner',
        name:'商户管理',
        component: () => import( '@/views/commercialOwner/index.vue'),
      },
      { // 商户订单管理
        path: '/commercialOwner/order',
        name:'商户订单管理',
        component: () => import( '@/views/commercialOwner/orderManage/index.vue'),
      },
      { // 用户管理
          path: '/rights/user',
          name:'用户管理',
          component: () => import( '@/views/authorityManage/usersManage/index.vue'),
      },
      { // 角色管理
          path: '/rights/role',
          name:'角色管理',
          component: () => import(/* webpackChunkName: "about" */ '@/views/authorityManage/rolesManage/index.vue'),
      },
      { // 菜单管理
        path: '/rights/menu',
        name:'菜单管理',
        component: () => import(/* webpackChunkName: "about" */ '@/views/authorityManage/menuManage/index.vue'),
      },
      { // 客服问答列表
          path: '/customer',
          name:'客服问答列表',
          component: () => import('@/views/customerService/index.vue'),
      },
      { // 资讯管理
          path: '/newsManage',
          name:'资讯管理',
          component: () => import('@/views/newsManage/index.vue'),
      },
      { // 反馈与建议
        path: '/suggestions',
        name:'反馈与建议',
        component: () => import('@/views/suggestions/index.vue'),
      },
      { // 监测站大屏
        path:'/largeScreen',
        name:'监测站大屏',
        component: () => import(/* webpackChunkName: "about" */ '@/views/largeScreen.vue'),
      },
      { // 充电桩大屏
        path:'/chargePileScreen',
        name:'充电桩大屏',
        component: () => import(/* webpackChunkName: "about" */ '@/views/largeScreen/chargePileScreen.vue'),
      },
      { // 新能源充电桩经营报表
        path:'/reportFormsManage/carUnitReport',
        name:'新能源充电桩经营报表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/reportFormsManage/carUnitReport.vue'),
      },
      { // 电瓶车充电桩经营报表
        path:'/reportFormsManage/batteryCarUnitReport',
        name:'电瓶车充电桩经营报表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/reportFormsManage/batteryCarUnitReport.vue'),
      },
      { // 全小区数据统计报表
        path:'/reportFormsManage/statistics',
        name:'全小区数据统计报表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/reportFormsManage/statistics.vue'),
      },
      { // 电动车全小区数据统计报表
        path:'/reportFormsManage/batteryStatistics',
        name:'电动车全小区数据统计报表',
        component: () => import(/* webpackChunkName: "about" */ '@/views/reportFormsManage/batteryStatistics.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router;
