import router from '@/router/index';
const state = {
    menuArr:[
        { // 站点管理
            path: '/serviceStation',
            name: 'stationManage',
            name_cn:'站点管理',
            icon: 'el-icon-s-home',
            component: () => import('@/views/stationManage/index.vue'),
            children:[]
        },
        { // 车主管理
            path: '/eBikeuser',
            name: 'ownerManage',
            name_cn:'车主管理',
            icon: 'el-icon-user-solid',
            component: () => import('@/views/ownerManage/index.vue'),
            children:[]
        },
        { // 异常记录
            path: '/abnormalRecord',
            name: 'abnormalRecords',
            name_cn:'异常记录',
            icon: 'el-icon-warning',
            component: () => import('@/views/abnormalRecords/index.vue'),
            children:[]
        },
        { // 小区管理
            path: '/unitSystem',
            name: 'neighbourhoodManage',
            name_cn:'小区管理',
            icon: 'el-icon-office-building',
            component: () => import('@/views/neighbourhoodManage/index.vue'),
            children:[]
        },
        { // 版本管理
            path: '/version',
            name: 'versionManage',
            name_cn:'版本管理',
            icon: 'el-icon-upload',
            component: () => import( '@/views/versionManage/index.vue'),
            children:[]
        },
        { // 设备管理
            path: '/device',
            name: 'equipManage',
            name_cn:'设备管理',
            icon: 'el-icon-cpu',
            component: {render: (e) => e("router-view")},
            children:[{ // 车辆管理
                path: '/device/eBike',
                name: 'carManage',
                name_cn:'车辆管理',
                component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/carManage/index.vue'),
            },{ // 感应器管理
                path: '/device/inductor',
                name: 'inductorManage',
                name_cn:'感应器管理',
                component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/inductorManage/index.vue'),
            },{ // 道闸管理
                path: '/device/barrierGate',
                name: 'brakeManage',
                // @ts-ignore
                name_cn:'道闸管理',
                component: () => import(/* webpackChunkName: "about" */ '@/views/equipManage/brakeManage/index.vue'),
            }]
        },
        { // 权限管理
            path: '/rights',
            name: 'authorityManage',
            name_cn:'权限管理',
            icon: 'el-icon-menu',
            component: {render: (e) => e("router-view")},
            children:[{ // 用户管理
                path: '/rights/user',
                name: 'usersManage',
                name_cn:'用户管理',
                component: () => import( '@/views/authorityManage/usersManage/index.vue'),
            },{ // 角色管理
                path: '/rights/role',
                name: 'rolesManage',
                name_cn:'角色管理',
                component: () => import(/* webpackChunkName: "about" */ '@/views/authorityManage/rolesManage/index.vue'),
            },]
        },
        { // 客服问答列表
            path: '/customer',
            name: 'customerService',
            name_cn:'客服问答列表',
            icon: 'el-icon-s-comment',
            component: () => import('@/views/customerService/index.vue'),
            children:[]
        },
    ],
}

const mutations = {
    add: (state:any,view: any)=> {
        state.menuArr.push(view)
    },
    delete: (state: any, view: any)=> {
        let index = state.menuArr.indexOf(view)
        if (index > -1) {
            state.menuArr.splice(index, 1)
        }
    },
    set: (state: any, view: any)=> {
        state.menuArr = view
        let menu_list = []
        view.forEach((value,key)=>{
            let children = [];
            if(value['children'].length>0){
                value['children'].forEach((v,k)=>{
                    children.push({
                        path: v['path'],
                        name: v['name'],
                        name_cn:v['name_cn'],
                        icon: v['icon'],
                        component:() => import(/* webpackChunkName: "about" */ v['comp_path']),
                        children:[]
                    })
                })
            }                                                                                                                                                                                                                                                                                                                                   
            menu_list.push({
                path: value['path'],
                name: value['name'],
                name_cn:value['name_cn'],
                icon: value['icon'],
                component:() => import(/* webpackChunkName: "about" */ value['comp_path']),
                children:children
            })
        })
        router.options.routes = menu_list
    },
    changeFirstMenu:(state: any, firstMneu: any)=>{
        state.menuArr[0].redirect=firstMneu;
    }
}

export default {
    namespaced: true,  // 这个是命名空间，用来区分不同的模块，我是根据文件的名字来区分
    state,
    mutations,
};
