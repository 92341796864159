<template>
  <section>
    <router-view></router-view>
  </section>
</template>

<script>
  import MenuContainer from '@/components/menu';
  import HeadContainer from '@/components/HeadContainer';
  import Login from '@/views/Login.vue';

  import {logout,getTreeMenu} from '@/api/public.js';

  export default {
    name: 'App',
    components: {MenuContainer, HeadContainer,Login},
    data() {
      return {
        title: '',
        crumb: [],
        active_name: '',
        isLogin:false,
        flagPc:true,
        activePath:"",
      }
    },
    created(){},
    methods: {
      handleMenuActive(e,child) {
        this.activePath = e.name_cn;
        if(child){
          this.title = child.name_cn;
          this.active_name = child.name_cn;
        }else{
          this.title = e.name_cn;
          this.active_name = e.name_cn;
        }
      },
      handleHeadCrumbActive(e) {
        this.active_name = e
      },
      // 登陆成功进入主页
      goHome(isLogin){
        this.isLogin = isLogin;
      },
      // 退出登录
      logOut(){
        this.$confirm('确认要退出登陆吗？').then(()=>{
          logout({}).then(res=>{
            if(res.success){
              sessionStorage.removeItem('userAccount');
              sessionStorage.removeItem('token');
              this.isLogin=false;
              sessionStorage.removeItem('login');
              localStorage.removeItem('activePath')
              localStorage.removeItem('userInfo')
            }
          })
        })
        .catch(()=>{})
      },
      // 监听事件
      storeage(type){
        if(type===1){
          window.addEventListener("beforeunload", () => {
            sessionStorage.setItem('login',this.isLogin);
          });
        }else{
          window.removeEventListener("beforeunload", () => {});
        }
      },
    },
    mounted(){
      getTreeMenu().then(res=>{
        if(res.code == 200){
          sessionStorage.setItem('login',true);
          this.$bus.$emit('isLogin',true);
        }else if(res.code == 401){
          sessionStorage.setItem('login',false);
          this.$bus.$emit('isLogin',false);
        }
      })
      this.$nextTick(()=>{
        this.$bus.$on('isLogin',(flag)=>{
          // if(!flag){
          //   this.isLogin=flag;
          // }
          this.isLogin=flag;
        });
        const res = sessionStorage.getItem('login') ? sessionStorage.getItem('login') : false;
        if(res){
          this.isLogin=res;
          sessionStorage.setItem('login','');
          this.$forceUpdate();
        }
        this.storeage(1);//刷新前保持islogin的状态
      })
    },
    destroyed(){
      sessionStorage.setItem('login',this.isLogin);
      this.storeage(2)//组件销毁前移除监听事件
      this.$bus.$off('isLogin')
    }
  }
</script>

<style lang="scss" >
  @import "@/static/default";
  @import "@/static/public";
  // 分页组件样式
  .pagination{
    background: #fff;
  }
  .tangram-suggestion {
      z-index: 9999;
    }
  /deep/ .el-drawer__body{
   overflow:auto !important;
 }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow:auto;
    height: 100%;
  }
  .el-scrollbar{
    padding-bottom: 11px !important;
  }
  /deep/ .el-drawer__open .el-drawer.rtl{
    overflow: auto !important;
  }
  div {
    box-sizing: border-box;
  }

  #manage-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(240, 242, 245, 1);
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    overflow:auto;
  }

  .header-container {
    height: 82px !important;
    padding: 0 !important;
  }

  .main-container {
    width: 100%;
    height: 100%;
    padding: 24px !important;
    overflow:auto;
  }

  .nav-container {
    width: 208px !important;
    height: 100vh;
    overflow: hidden !important;
    background-color: $primary-color;
    box-shadow: 3px 0px 8px 0px rgba(0, 21, 41, 0.35);

    .nav-top {
      width: 100%;
      height: 23px;
      margin-bottom: 30px;
      margin-top: 29px;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-image {
        width: 60%;
      }
    }

    .scroll-bar {
      width: 100%;
      overflow-x: hidden;
      height: calc(100vh - 23px - 59px);
    }
  }

  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }

</style>
