import router from '@/router/index'
import {getMerchantList} from '@/api/public.js';
const state = {
    userData:{},
    merchantOptions:[],
    isMap:false,
    activePath:'/'
}
const mutations = {
    set: (state: any, view: any)=> {
        state.userData = view
    },
    // getMerchant:(state: any, view: any)=>{
    //     let data = {
    //         pageNo:1,
    //         pageSize:1000,
    //         param:{
    //             name:"",
    //         }
    //     }
    //     getMerchantList(data).then(res=>{
    //         if(res.success){
    //             state.merchantOptions = res.data.list;
    //         }
    //     })
    // }
}

export default {
    namespaced: true,  // 这个是命名空间，用来区分不同的模块，我是根据文件的名字来区分
    state,
    mutations,
};
