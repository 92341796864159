import axios, { ResponseData } from './base.ts';
import { AxiosPromise } from 'axios';

export function getBaseUrl () {
  return 'https://www.nuoyayunpingtai.com/nyy/'
}


// 公共
  // 获取省市区列表
  export function getRegionTree(data={}){
    return axios.request({
      url:`/admin/region/getTree`,
      method:'post',
      data:data,
    })
  }
  // 通过子级获取父级id
  export function getParentTree(data={}){
    return axios.request({
      url:`/admin/region/getParentTree`,
      method:'post',
      type:'special',
      data:data,
    })
  }
  // 获取菜单列表
  export function getTreeMenu(data={}){
    return axios.request({
      url:`/admin/menu/getTreeMenu`,
      method:'get',
      data:data,
    })
  }
  // 创建菜单
  export function createTreeMenu(data={}){
    return axios.request({
      url:`/admin/menu/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑菜单
  export function updateTreeMenu(data={}){
    return axios.request({
      url:`/admin/menu/update`,
      method:'post',
      data:data,
    })
  }
  // 修改用户密码
  export function modefyPassword(data = {}) {
    return axios.request({
      url: `admin/user/modifyUserPassword`,
      method: "post",
      data: data,
    })
  }

  // 获取不同角色菜单权限
  export function selectMenuByRoleId(data={}){
    return axios.request({
      url:`/admin/menu/selectMenuByRoleId`,
      method:'post',
      data:data,
    })
  }

  // 获取公钥
  export function getRSAPublicKey(data = {}) {
    return axios.request({
      url: `/admin/getRSAPublicKey`,
      method: "post",
      data: data,
    })
  }
  // 登录
  export function login(data = {}) {
    return axios.request({
      url: `/admin/login`,
      method: "post",
      data: data,
    })
  }
  // 登出
  export function logout(data = {}) {
    return axios.request({
      url: `/admin/logout`,
      method: "post",
      data: data,
    })
  }
  
  // 文件上传
  export function upload(data = {}) {
    return axios.request({
      url: `/admin/otafile/getPreUploadUrl`,
      method: "post",
      data: data,
    })
  }

  // 关系解绑
  export function unbindByRelation(data = {}) {
    return axios.request({
      url: `/admin/relation/unbindByRelation`,
      method: "post",
      data: data,
    })
  }
  // 解绑小区管理员
  export function unbindUserByUnit(data = {}) {
    return axios.request({
      url: `/admin/user/unbindUserByUnit`,
      method: "post",
      data: data,
    })
  }
  // 管理员绑定站点
  export function bindSiteUser(data = {}) {
    return axios.request({
      url: `/admin/user/bindSiteUser`,
      method: "post",
      data: data,
    })
  }
  // 管理员解绑站点
  export function unbindSiteUser(data = {}) {
    return axios.request({
      url: `/admin/user/unbindSiteUser`,
      method: "post",
      data: data,
    })
  }
  // 获取字典字段
  export function getDictionaryByType(data = {}) {
    return axios.request({
      url: `/admin/dictionary/getDictionaryByType?param=${data.type}`,
      method: "get",
      type:"special",
      data: data,
    })
  }
  


// 站点管理
  // 获取站点列表
  export function getStationList(data={}){
    return axios.request({
      url:`/admin/site/pageList`,
      method:'post',
      data:data,
    })
  }
  // 获取站点类型列表
  export function getStationTypeList(data={}){
    return axios.request({
      url:`/admin/site/typeList`,
      method:'post',
      data:data,
    })
  }
  // 获取周日期枚举
  export function getSiteWeekType(data={}){
    return axios.request({
      url:`/admin/site/siteWeekType`,
      method:'get',
      data:data,
    })
  }
  // 获取工作日枚举
  export function getSiteWorkingType(data={}){
    return axios.request({
      url:`/admin/site/siteWorkingType`,
      method:'get',
      data:data,
    })
  }
  // 获取站点详情
  export function getStationDetail(data={}){
    return axios.request({
      url:`/admin/site/detail`,
      method:'post',
      data:data,
    })
  }
  // 新增站点
  export function addStation(data={}){
    return axios.request({
      url:`/admin/site/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑站点
  export function updateStation(data={}){
    return axios.request({
      url:`/admin/site/update`,
      method:'PUT',
      data:data,
    })
  }
  // 删除站点
  export function delStation(data={}){
    return axios.request({
      url:`/admin/site/delete`,
      method:'put',
      data:data,
    })
  }
  // 获取安装站点预约记录
  export function getInstallAppointmentList(data={}){
    return axios.request({
      url:`/admin/appointmentRecord/installList`,  
      method:'post',
      data:data,
    })
  }
  // 安装站点重新预约
  export function createAppointment(data={}){
    return axios.request({
      url:`/admin/appointmentRecord/create`,  
      method:'post',
      data:data,
    })
  }
  // 获取检测站点预约记录
  export function getDetectionAppointmentList(data={}){
    return axios.request({
      url:`/admin/appointmentRecord/detectionList`,  
      method:'post',
      data:data,
    })
  }
  // 创建检测信息
  export function createDetection(data={}){
    return axios.request({
      url:`/admin/detection/createDetection`,
      method:'post',
      data:data,
    })
  }
  // 检测记录补充数据
  export function addDetection(data={}){
    return axios.request({
      url:`/admin/detection/supplement`,
      method:'post',
      data:data,
    })
  }
  // 查看检测记录详情
  export function getDetectionDetail(data={}){
    return axios.request({
      url:`/admin/detection/getDetectionById`,
      method:'post',
      data:data,
    })
  }
  // 导出监测信息详情
  export function exportDetectionList(data={}){
    return axios.request({
      url:`/admin/appointmentRecord/exportDetectionList`,
      method:'post',
      data:data,
    })
  }
  // 获取安装站点预约记录
  export function getRecordOfAppointment(data={}){
    return axios.request({
      url:`/admin/site/recordOfAppointment`,
      method:'post',
      data:data,
    })
  }
  // 设备绑定车辆
  export function eBikeBindDevice(data={}){
    return axios.request({
      url:`/admin/appointmentRecord/eBikeBindDevice`,
      method:'post',
      data:data,
    })
  }
  // 通过预约id设备解绑车辆
  export function unbindEbikeByDevice(data={}){
    return axios.request({
      url:`/admin/appointmentRecord/unbindEbikeByDevice`,
      method:'post',
      data:data,
    })
  }
  // 通过车辆id设备解绑车辆a
  export function unBindByBikeId(data={}){
    return axios.request({
      url:`/admin/eBike/unBindByBikeId`,
      method:'post',
      data:data,
    })
  }
  


// 车主管理
  // 获取车主列表
  export function getEBikeUserList(data={}){
    return axios.request({
      url:`/admin/eBikeUser/pageList`,
      method:'post',
      data:data,
    })
  }


// 异常管理
  // 获取异常记录列表
  export function getAbnormalList(data={}){
    return axios.request({
      url:`/admin/abnormal/pageList`,
      method:'post',
      data:data,
    })
  }
  // 处理异常
  export function handleAbnormal(data={}){
    return axios.request({
      url:`/admin/abnormal/handle`,
      method:'post',
      data:data,
    })
  }
  // 查看超速异常详情
  export function checkSpeedDetail(data={}){
    return axios.request({
      url:`/admin/abnormal/speed/detail?param=${data.param}`,
      method:'get',
      data:data,
    })
  }
  // 导出异常
  export function exportAbnormalList(data={}){
    return axios.request({
      url:`/admin/abnormal/exportAbnormalList`,
      method:'post',
      // responseType: "blob",
      data:data,
    })
  }


// 小区管理
  // 获取小区列表
  export function getNeighbourhoodList(data={}){
    return axios.request({
      url:`/admin/unit/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增小区
  export function addNeighbourhood(data={}){
    return axios.request({
      url:`/admin/unit/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑小区
  export function updateNeighbourhood(data={}){
    return axios.request({
      url:`/admin/unit/update`,
      method:'put',
      data:data,
    })
  }

  // 删除小区
  export function delNeighbourhood(data={}){
    return axios.request({
      url:`/admin/unit/delete`,
      method:'put',
      data:data,
    })
  }
  // 设置小区范围
  export function setNeighbourhoodRange(data={}){
    return axios.request({
      url:`/admin/unit/settingCellRange`,
      method:'post',
      data:data,
    })
  }
  // 进入小区申请列表
  export function unitApplyList(data={}){
    return axios.request({
      url:`/admin/enter/unit/apply/pageList`,
      method:'post',
      data:data,
    })
  }
  // 进入小区申请审核
  export function auditApplyUnit(data={}){
    return axios.request({
      url:`/admin/enter/unit/audit`,
      method:'post',
      data:data,
    })
  }

  // 小区绑定管理员
  export function neighbourhoodBindingAdmin(data={}){
    return axios.request({
      url:`admin/user/bindUserByUnit`,
      method:'post',
      data:data,
    })
  }
  // 绑定管理员时获取后台小区管理员角色用户
  export function getUnitAdmin(data={}){
    return axios.request({
      url:`/admin/user/selectUnitUserByRole`,
      method:'get',
      data:data,
    })
  }
  // 绑定站点管理员时获取后台站点管理员角色用户
  export function selectSiteUserByRole(data={}){
    return axios.request({
      url:`/admin/user/selectSiteUserByRole`,
      method:'get',
      data:data,
    })
  }
  // 获取小区内部停车区列表
  export function getUnitAreaList(data={}){
    return axios.request({
      url:`/admin/unit/internal/area/list?param=${data.param}`,
      method:'get',
      type:'special',
      data:data,
    })
  }
  // 添加小区内部停车区
  export function addUnitArea(data={}){
    return axios.request({
      url:`/admin/unit/internal/area/create`,
      method:'post',
      data:data,
    })
  }
  // 删除小区内部停车区
  export function delUnitArea(data={}){
    return axios.request({
      url:`/admin/unit/internal/area/delete`,
      method:'post',
      data:data,
    })
  }
  // 停车区感应器列表
  export function getInductorListByAreaId(data={}){
    return axios.request({
      url:`/admin/inductor/listByAreaId`,
      method:'post',
      data:data,
    })
  }
  // 停车区4G开关列表
  export function getSwitchListByAreaId(data={}){
    return axios.request({
      url:`/admin/wifi/Switch/listByAreaId`,
      method:'post',
      data:data,
    })
  }


// 设备管理 - 车辆管理
  // 获取车辆列表
  export function getEBikeList(data={}){
    return axios.request({
      url:`/admin/eBike/pageList`,
      method:'post',
      data:data,
    })
  }
  // 查询车辆详情
  export function getEBikeById(data={}){
    return axios.request({
      url:`/admin/eBike/getEBikeById`,
      method:'post',
      data:data,
    })
  }
  // 获取车辆通讯记录
  export function getEBikeBycommunication(data={}){
    return axios.request({
      url:`/admin/eBike/communication/record`,
      method:'post',
      data:data,
    })
  }
  // 车辆绑定小区
  export function bindUnitByEBike(data={}){
    return axios.request({
      url:`/admin/unit/bindUnitByEBike`,
      method:'post',
      data:data,
    })
  }
  // 车辆解绑小区
  export function unbindUnitByEBike(data={}){
    return axios.request({
      url:`/admin/unit/unbindUnitByEBike`,
      method:'post',
      data:data,
    })
  }
  // 导出数据
  export function exportBikeList(data={}){
    return axios.request({
      url:`/admin/eBike/exportBikeList`,
      method:'post',
      data:data,
    })
  }
   // 车辆解锁、上锁
   export function handleLockCar(data={}){
    return axios.request({
      url:`/admin/eBike/switch/lock`,
      method:'post',
      data:data,
    })
  }

  


// 设备管理 - 感应器管理
  // 获取感应器列表
  export function getInductorList(data={}){
    return axios.request({
      url:`/admin/inductor/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增感应器/admin/inductor/create
  export function createInductor(data={}){
    return axios.request({
      url:`/admin/inductor/create`,
      method:'post',
      data:data,
    })
  }
  // 删除感应器/admin/inductor/delete
  export function deleteInductor(data={}){
    return axios.request({
      url:`/admin/inductor/delete`,
      method:'put',
      data:data,
    })
  }
  // 修改感应器
  export function updateInductor(data={}){
    return axios.request({
      url:`/admin/inductor/update`,
      method:'put',
      data:data,
    })
  }
  // 重启感应器
  export function restartInductor(data={}){
    return axios.request({
      url:`/admin/inductor/restart`,
      method:'post',
      data:data,
    })
  }
  // 设置时间间隔
  export function setIntervalInductor(data={}){
    return axios.request({
      url:`/admin/inductor/setInterval`,
      method:'post',
      data:data,
    })
  }
  // 设置功率
  export function setPowerInductor(data={}){
    return axios.request({
      url:`/admin/inductor/setPower`,
      method:'post',
      data:data,
    })
  }
  // 感应器通讯记录
  export function getInductorCommunication(data={}){
    return axios.request({
      url:`/admin/inductor/communication/record`,
      method:'post',
      data:data,
    })
  }
  // 感应器感应记录
  export function getInductorPassage(data={}){
    return axios.request({
      url:`/admin/inductor/passage/record`,
      method:'post',
      data:data,
    })
  }



// 设备管理 - 道闸管理
  // 获取道闸列表
  export function getBarrierGateList(data={}){
    return axios.request({
      url:`/admin/barrierGate/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增道闸
  export function createBarrierGate(data={}){
    return axios.request({
      url:`/admin/barrierGate/create`,
      method:'post',
      data:data,
    })
  }
  // 删除道闸
  export function deleteBarrierGate(data={}){
    return axios.request({
      url:`/admin/barrierGate/delete`,
      method:'put',
      data:data,
    })
  }
  // 编辑道闸
  export function updateBarrierGate(data={}){
    return axios.request({
      url:`/admin/barrierGate/update`,
      method:'put',
      data:data,
    })
  }
  // 关闭道闸
  export function closeBarrierGate(data={}){
    return axios.request({
      url:`/admin/barrierGate/close`,
      method:'post',
      data:data,
    })
  }
  // 打开道闸
  export function openBarrierGate(data={}){
    return axios.request({
      url:`/admin/barrierGate/open`,
      method:'post',
      data:data,
    })
  }
  // 重启道闸
  export function restartBarrierGate(data={}){
    return axios.request({
      url:`/admin/barrierGate/restart`,
      method:'post',
      data:data,
    })
  }
  // 设置时间间隔
  export function setIntervalBarrierGate(data={}){
    return axios.request({
      url:`/admin/barrierGate/setInterval`,
      method:'post',
      data:data,
    })
  }
  // 设置超时时间
  export function setTimeoutBarrierGate(data={}){
    return axios.request({
      url:`/admin/barrierGate/setTimeout`,
      method:'post',
      data:data,
    })
  }
  // 设置功率
  export function setPowerBarrierGate(data={}){
    return axios.request({
      url:`/admin/barrierGate/setPower`,
      method:'post',
      data:data,
    })
  }
  // 获取道闸通讯记录
  export function getBrakeCommunication(data={}){
    return axios.request({
      url:`/admin/barrierGate/communication/record`,
      method:'post',
      data:data,
    })
  }
  // 获取道闸通行记录
  export function getBrakePassage(data={}){
    return axios.request({
      url:`/admin/barrierGate/passage/record`,
      method:'post',
      data:data,
    })
  }
  // 道闸摄像头是否开启头盔识别
  export function setPhotoStatus(data={}){
    return axios.request({
      url:`/admin/barrierGate/set/photoStatus`,
      method:'post',
      data:data,
    })
  }

// 设备管理 - 红外头盔识别管理
  // 获取红外头盔识别设备列表
  export function getInfraredHelmetList(data={}){
    return axios.request({
      url:`/admin/infraredHelmet/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增红外头盔识别设备
  export function addInfraredHelmet(data={}){
    return axios.request({
      url:`/admin/infraredHelmet/create`,
      method:'post',
      data:data,
    })
  }
  // 修改红外头盔识别设备
  export function editInfraredHelmet(data={}){
    return axios.request({
      url:`/admin/infraredHelmet/update`,
      method:'put',
      data:data,
    })
  }
  // 删除红外头盔识别设备
  export function delInfraredHelmet(data={}){
    return axios.request({
      url:`/admin/infraredHelmet/delete`,
      method:'put',
      data:data,
    })
  }
  // 打开红外头盔识别设备
  export function openInfraredHelmet(data={}){
    return axios.request({
      url:`/admin/infraredHelmet/open`,
      method:'post',
      data:data,
    })
  }
  // 配置红外头盔识别设备
  export function dispositionInfraredHelmet(data={}){
    return axios.request({
      url:`/admin/infraredHelmet/dispositionDevice`,
      method:'post',
      data:data,
    })
  }
  // 获取红外头盔识别设备通讯记录
  export function getInfraredHelmetCommunication(data={}){
    return axios.request({
      url:`/admin/infraredHelmet/communication/record`,
      method:'post',
      data:data,
    })
  }
  // 获取红外头盔识别设备通行记录
  export function getInfraredHelmetPassage(data={}){
    return axios.request({
      url:`/admin/infraredHelmet/passage/record`,
      method:'post',
      data:data,
    })
  }
  // 红外头盔识别是否开启
  export function setPhotoStatusInfraredHelmet(data={}){
    return axios.request({
      url:`/admin/infraredHelmet/set/photoStatus`,
      method:'post',
      data:data,
    })
  }


  // 开关管理
  export function getSwitchList(data={}){
    return axios.request({
      url:`/admin/wifi/Switch/page`,
      method:'post',
      data:data,
    })
  }

  // 路由器管理
  // 查询列表
  export function getRouterList(data={}){
    return axios.request({
      url:`/admin/router/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增路由
  export function addRouter(data={}){
    return axios.request({
      url:`/admin/router/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑路由
  export function editRouter(data={}){
    return axios.request({
      url:`/admin/router/update`,
      method:'post',
      data:data,
    })
  }
  // 删除路由
  export function delRouter(data={}){
    return axios.request({
      url:`/admin/router/deleted`,
      method:'post',
      data:data,
    })
  }
  // 新增WiFi开关
  export function addWifiSwitch(data={}){
    return axios.request({
      url:`/admin/wifi/Switch/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑WiFi开关
  export function editWifiSwitch(data={}){
    return axios.request({
      url:`/admin/wifi/Switch/update`,
      method:'post',
      data:data,
    })
  }
  // 删除WiFi开关
  export function delWifiSwitch(data={}){
    return axios.request({
      url:`/admin/wifi/Switch/deleted`,
      method:'post',
      data:data,
    })
  }
  // 获取WiFi开关通讯记录
  export function getWifiSwitchRecords(data={}){
    return axios.request({
      url:`/admin/wifi/Switch/record`,
      method:'post',
      data:data,
    })
  }
  // WiFi开关 通断电  
  export function lockSwitch(data={}){
    return axios.request({
      url:`/admin/wifi/Switch/lock`,
      method:'post',
      data:data,
    })
  }


// 设备管理 - 地锁管理
  // 列表查询
  export function getFloorLockList(data={}){
    return axios.request({
      url:`admin/ground/lock/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增地锁
  export function addFloorLock(data={}){
    return axios.request({
      url:`/admin/ground/lock/create`,
      method:'post',
      data:data,
    })
  }
  // 修改地锁
  export function editFloorLock(data={}){
    return axios.request({
      url:`/admin/ground/lock/update`,
      method:'post',
      data:data,
    })
  }
  // 删除地锁
  export function delFloorLock(data={}){
    return axios.request({
      url:`/admin/ground/lock/delete`,
      method:'post',
      data:data,
    })
  }
  // 获取地锁类型
  export function getFloorLockTypeList(data={}){
    return axios.request({
      url:`/admin/ground/lock/type/list`,
      method:'get',
      data:data,
    })
  }
  // 获取通讯记录
  export function getFloorLockRecords(data={}){
    return axios.request({
      url:`/admin/ground/lock/communication/record`,
      method:'post',
      data:data,
    })
  }
  // 解锁
  export function unlockFloorLock(data={}){
    return axios.request({
      url:`admin/ground/lock/unlock`,
      method:'post',
      data:data,
    })
  }
  // 上锁
  export function lockFloorLock(data={}){
    return axios.request({
      url:`admin/ground/lock/lock`,
      method:'post',
      data:data,
    })
  }
  // 获取预约记录
  export function getParkAppointRecords(data={}){
    return axios.request({
      url:`admin/parking/reservation/record/pageList`,
      method:'post',
      data:data,
    })
  }

  // 设备管理 - 检测房管理
  // 新增
  export function createDetectionHouse(data={}){
    return axios.request({
      url:`admin/testing/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑
  export function updateDetectionHouse(data={}){
    return axios.request({
      url:`admin/testing/update`,
      method:'post',
      data:data,
    })
  }
  // 删除
  export function deleteDetectionHouse(data={}){
    return axios.request({
      url:`admin/testing/deleted`,
      method:'post',
      data:data,
    })
  }
  // 查询列表
  export function getDetectionHouseList(data={}){
    return axios.request({
      url:`admin/testing/page`,
      method:'post',
      data:data,
    })
  }
  // 查询详情
  export function getDetectionHouseDetail(data={}){
    return axios.request({
      url:`admin/testing/detail`,
      method:'post',
      data:data,
    })
  }
  // 设备初始化
  export function initDetectionHouse(data={}){
    return axios.request({
      url:`admin/testing/deviceInitialize`,
      method:'post',
      data:data,
    })
  }
  // 站点绑定检测房
  export function bindDetectionHouse(data={}){
    return axios.request({
      url:`admin/testing/bindTestingRoom`,
      method:'post',
      data:data,
    })
  }
  // 站点解绑检测房
  export function unBindDetectionHouse(data={}){
    return axios.request({
      url:`admin/testing/unbindTestingRoom`,
      method:'post',
      data:data,
    })
  }
  // 检测房配置
  export function dispositionDetectionHouse(data={}){
    return axios.request({
      url:`admin/testing/deviceDisposition`,
      method:'post',
      data:data,
    })
  }
  


// 版本管理 - 车载设备版本
  // 获取车载设备硬件版本列表
  export function getPageHardwareVersion(data={}){
    return axios.request({
      url:`/admin/otafile/getListHardwareVersion`,
      method:'post',
      data:data,
    })
  }
  // 新增硬件版本
  export function addHardwareVersion(data={}){
    return axios.request({
      url:`/admin/otafile/addHardwareVersion`,
      method:'post',
      data:data,
    })
  }
  // 修改硬件版本
  export function updateHardwareVersion(data={}){
    return axios.request({
      url:`admin/otafile/updateHardwareVersion`,
      method:'post',
      data:data,
    })
  }
  // 查看硬件版本下所属的软件版本
  export function getListSoftwareVersion(data={}){
    return axios.request({
      url:`/admin/otafile/getPageSoftwareVersion`,
      method:'post',
      data:data,
    })
  }
  
  // 新增某个硬件版本下的软件版本
  export function addSoftwareVersion(data={}){
    return axios.request({
      url:`/admin/otafile/addSoftwareVersion`,
      method:'post',
      data:data,
    })
  }
  // 修改软件版本状态（启用、禁用）
  export function updateSoftwareVersion(data={}){
    return axios.request({
      url:`/admin/otafile/updateSoftwareVersion`,
      method:'post',
      data:data,
    })
  }
  // 设备升级
  export function deviceUpgrade(data={}){
    return axios.request({
      url:`/admin/otafile/deviceUpgrade`,
      method:'post',
      data:data,
    })
  }
  // 获取最新一条版本更新记录
  export function latestUpgradeRecord(data={}){
    return axios.request({
      url:`/admin/otafile/latestUpgradeRecord`,
      method:'post',
      data:data,
    })
  }




// 权限管理 - 用户管理
  // 获取后台用户列表
  export function getUserList(data={}){
    return axios.request({
      url:`/admin/user/pageList`,
      method:'post',
      data:data,
    })
  }
  // 获取当前登录后台用户信息
  export function getUserInfo(data={}){
    return axios.request({
      url:`/admin/user/getUserInfo`,
      method:'post',
      data:data,
    })
  }
  // 新增用户/admin/user/create
  export function addUser(data={}){
    return axios.request({
      url:`/admin/user/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑用户/admin/user/update
  export function updateUser(data={}){
    return axios.request({
      url:`/admin/user/update`,
      method:'post',
      data:data,
    })
  }
  // 重置用户密码
  export function resetUserPassword(data={}){
    return axios.request({
      url:`/admin/user/resetUserPassword`,
      method:'post',
      // type:"special",
      data:data,
    })
  }
  // 打卡上下班/admin/user/updateWork
  export function updateUserWork(data={}){
    return axios.request({
      url:`/admin/user/updateWork`,
      method:'post',
      type:"special",
      data:data,
    })
  }
  // 冻结或解冻用户
  export function forbiddenUser(data={}){
    return axios.request({
      url:`/admin/user/forbiddenUser`,
      method:'post',
      type:"special",
      data:data,
    })
  }
  // 用户工作状态枚举
  export function getSystemWorkStatusType(data={}){
    return axios.request({
      url:`/admin/user/systemWorkStatusType`,
      method:'get',
    })
  }

  
// 权限管理 - 角色管理
  // 新增角色
  export function addRole(data={}){
    return axios.request({
      url:`/admin/role/create`,
      method:'post',
      data:data,
    })
  } 
  // 获取角色列表
  export function getRoleList(data={}){
    return axios.request({
      url:`/admin/role/list`,
      method:'get',
      data:data,
    })
  }
  // 获取角色详情
  export function getRoleInfo(data={}){
    return axios.request({
      url:`/admin/role/getRoleInfo`,
      method:'get',
      data:data,
    })
  }
  // 修改角色
  export function updateRole(data={}){
    return axios.request({
      url:`/admin/role/update`,
      method:'post',
      data:data,
    })
  }
  // 角色菜单权限分配
  export function createMenu(data={}){
    return axios.request({
      url:`/admin/menu/createMenu`,
      method:'post',
      data:data,
    })
  }



// 客服问答列表
  // 获取客服问答列表
  export function getCustomerMsgList(data={}){
    return axios.request({
      url:`/admin/customerMsg/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增问答
  export function addCustomerMsg(data={}){
    return axios.request({
      url:`/admin/customerMsg/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑问答
  export function updateCustomerMsg(data={}){
    return axios.request({
      url:`/admin/customerMsg/update`,
      method:'put',
      data:data,
    })
  }
  // 删除问答
  export function delCustomerMsg(data={}){
    return axios.request({
      url:`/admin/customerMsg/delete`,
      method:'put',
      data:data,
    })
  }

  // 反馈与建议
  // 获取建议列表
  export function getFeedbackList(data={}){
    return axios.request({
      url:`/admin/feedback/list`,
      method:'post',
      data:data,
    })
  }

  // 大屏
  // 统计数据
  export function getStatistics(data={}){
    return axios.request({
      url:`/admin/statistics/data`,
      method:'post',
      // type:'special',
      data:data,
    })
  }

  // 异常统计数据
  export function getAbnormal(data={}){
    return axios.request({
      url:`/admin/statistics/abnormal`,
      method:'post',
      // type:'special',
      data:data,
    })
  }

  // 异常详情
  export function getAbnormalDetail(data={}){
    return axios.request({
      url:`/admin/statistics/abnormal/detail`,
      method:'post',
      data:data,
    })
  }
  // 其他未处理异常
  export function getUntreatedList(data={}){
    return axios.request({
      url:`/admin/abnormal/untreatedList`,
      method:'post',
      data:data,
    })
  }
  // 异常过温未处理列表
  export function getHighBatteryUntreated(data={}){
    return axios.request({
      url:`/admin/abnormal/highBatteryUntreated`,
      method:'post',
      data:data,
    })
  }


// 资讯管理
  // 列表查询
  export function getInformationList(data={}){
    return axios.request({
      url:`/admin/information/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增
  export function createInformation(data={}){
    return axios.request({
      url:`/admin/information/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑
  export function updateInformation(data={}){
    return axios.request({
      url:`/admin/information/update`,
      method:'post',
      data:data,
    })
  }
  // 删除
  export function deleteInformation(data={}){
    return axios.request({
      url:`/admin/information/deleted`,
      method:'post',
      data:data,
    })
  }
  // 详情
  export function getInformationDetail(data={}){
    return axios.request({
      url:`/admin/information/detail`,
      method:'post',
      data:data,
    })
  }
  // 置顶
  export function topInformation(data={}){
    return axios.request({
      url:`/admin/information/systemTop`,
      method:'post',
      data:data,
    })
  }
  // 取消置顶
  export function unTopInformation(data={}){
    return axios.request({
      url:`/admin/information/unTop`,
      method:'post',
      data:data,
    })
  }

// 商户管理
  // 列表查询
  export function getMerchantList(data={}){
    return axios.request({
      url:`/admin/merchant/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增
  export function addMerchant(data={}){
    return axios.request({
      url:`/admin/merchant/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑
  export function editMerchant(data={}){
    return axios.request({
      url:`/admin/merchant/update`,
      method:'post',
      data:data,
    })
  }
  // 删除
  export function delMerchant(data={}){
    return axios.request({
      url:`/admin/merchant/delete`,
      method:'post',
      data:data,
    })
  }
  // 详情
  export function getMerchantDetail(data={}){
    return axios.request({
      url:`/admin/merchant/detail`,
      method:'post',
      data:data,
    })
  }

  // 获取商户订单列表
  export function getMerchantOrderList(data={}){
    return axios.request({
      url:`/admin/merchant/order/pageList`,
      method:'post',
      data:data,
    })
  }
  // 新增商户订单
  export function addMerchantOrder(data={}){
    return axios.request({
      url:`/admin/merchant/order/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑商户订单
  export function editMerchantOrder(data={}){
    return axios.request({
      url:`/admin/merchant/order/update`,
      method:'post',
      data:data,
    })
  }
  // 删除商户订单
  export function delMerchantOrder(data={}){
    return axios.request({
      url:`/admin/merchant/order/delete`,
      method:'post',
      data:data,
    })
  }
  // 订单详情
  export function getMerchantOrderDetail(data={}){
    return axios.request({
      url:`/admin/merchant/order/detail`,
      method:'post',
      data:data,
    })
  }


  // 卡用户管理
  // 分页查询卡用户
  export function getCardUserList(data={}){
    return axios.request({
      url:`/admin/card/user/page`,
      method:'post',
      data:data,
    })
  }
  // 新增卡用户
  export function createCardUser(data={}){
    return axios.request({
      url:`/admin/card/user/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑卡用户
  export function updateCardUser(data={}){
    return axios.request({
      url:`/admin/card/user/update`,
      method:'post',
      data:data,
    })
  }
  // 删除卡用户
  export function deleteCardUser(data={}){
    return axios.request({
      url:`/admin/card/user/delete`,
      method:'post',
      data:data,
    })
  }
  // 卡用户绑定微信
  export function cardUserBindWechat(data={}){
    return axios.request({
      url:`/admin/card/user/bind/wechat`,
      method:'post',
      data:data,
    })
  }
  // 卡充值
  export function topupCard(data={}){
    return axios.request({
      url:`/admin/card/user/recharge`,
      method:'post',
      data:data,
    })
  }
  // 查询卡充值记录
  export function getTopUpRecords(data={}){
    return axios.request({
      url:`/admin/card/recharge/record/page`,
      method:'post',
      data:data,
    })
  }
  // 卡充值记录导出
  export function topUpRecordsEduce(data={}){
    return axios.request({
      url:`/admin/card/recharge/record/exportCardRechargeRecord`,
      method:'post',
      data:data,
    })
  }
  

  // 充电桩用户
  export function getChargingPileUser(data={}){
    return axios.request({
      url:`/admin/chargingPileUser/pageList`,
      method:'post',
      data:data,
    })
  }
  

  // 消费模板
  // 查询消费模板
  export function getTemplateList(data={}){
    return axios.request({
      url:`/admin/consume/template/list`,
      method:'post',
      data:data,
    })
  }
  // 创建消费模板
  export function createTemplate(data={}){
    return axios.request({
      url:`/admin/consume/template/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑消费模板
  export function updateTemplate(data={}){
    return axios.request({
      url:`/admin/consume/template/updateOrCreate`,
      method:'post',
      data:data,
    })
  }
  // 删除消费模板
  export function deleteTemplate(data={}){
    return axios.request({
      url:`/admin/consume/template/delete`,
      method:'post',
      data:data,
    })
  }


  // 充电桩管理
  // 查询充电桩列表
  export function getChargePileList(data={}){
    return axios.request({
      url:`/admin/chargingPile/pageList`,
      method:'post',
      data:data,
    })
  }
  // 删除充电桩
  export function deleteChargePile(data={}){
    return axios.request({
      url:`/admin/chargingPile/delete`,
      method:'post',
      data:data,
    })
  }
  // 新增充电桩
  export function createChargePile(data={}){
    return axios.request({
      url:`/admin/chargingPile/create`,
      method:'post',
      data:data,
    })
  }
  // 修改充电桩
  export function updateChargePile(data={}){
    return axios.request({
      url:`/admin/chargingPile/update`,
      method:'post',
      data:data,
    })
  }
  // 充电桩端口停止充电
  export function stopCharging(data={}){
    return axios.request({
      url:`/admin/chargingPile/stop/charging`,
      method:'post',
      data:data,
    })
  }
  // 充电桩维护
  export function maintainChargePile(data={}){
    return axios.request({
      url:`/admin/chargingPile/maintain`,
      method:'post',
      data:data,
    })
  }
  // 重启充电桩
  export function restartChargePile(data={}){
    return axios.request({
      url:`/admin/chargingPile/down/restart`,
      method:'post',
      data:data,
    })
  }
  // 下发充电桩消费模板
  export function carConsumption(data={}){
    return axios.request({
      url:`/admin/chargingPile/set/chargingConsumption`,
      method:'post',
      data:data,
    })
  }
  // 获取四轮车充电桩当前使用消费模板信息
  export function getCarConsumption(data={}){
    return axios.request({
      url:`/admin/chargingPile/get/carConsumption`,
      method:'post',
      data:data,
    })
  }
  // 获取两轮车充电桩当前使用消费模板信息
  export function getBikeConsumption(data={}){
    return axios.request({
      url:`/admin/chargingPile/get/bikeConsumption`,
      method:'post',
      data:data,
    })
  }
  // 更新二维码地址
  export function updateQrCode(data={}){
    return axios.request({
      url:`/admin/chargingPile/updateQrCode`,
      method:'post',
      data:data,
    })
  }
  // 设备转网
  export function changNetWork(data={}){
    return axios.request({
      url:`/admin/chargingPile/updateAddress`,
      method:'post',
      data:data,
    })
  }


  // 充电桩订单管理
  // 查询订单列表
  export function getChargingPileOrderList(data={}){
    return axios.request({  
      url:`admin/chargingPile/order/page`,
      method:'post',
      data:data,
    })
  }
  // 查询订单详情
  export function getChargingPileOrderDetail(data={}){
    return axios.request({
      url:`admin/chargingPile/order/get`,
      method:'post',
      data:data,
    })
  }
  // 订单导出
  export function listExportOrder(data={}){
    return axios.request({
      url:`admin/chargingPile/order/listExportOrder`,
      method:'post',
      data:data,
    })
  }

  // 开票
  // 分页查询开票申请
  export function getInvoiceList(data={}){
    return axios.request({
      url:`admin/invoice/pageList`,
      method:'post',
      data:data,
    })
  }
  // 查询开票详情
  export function getInvoiceDetail(data={}){
    return axios.request({
      url:`admin/invoice/detail`,
      method:'post',
      data:data,
    })
  }
  // 确认开票
  export function makeOutAnInvoice(data={}){
    return axios.request({
      url:`admin/invoice/makeOutAnInvoice`,
      method:'post',
      data:data,
    })
  }

  // 报表管理
  // 获取近七天实收金额
  export function getSevenAmount(data={}){
    return axios.request({
      url:`admin/charging/pile/operation/report/amount/seven`,
      method:'post',
      data:data,
    })
  }
  // 获取近七天订单数量
  export function getSevenOrder(data={}){
    return axios.request({
      url:`admin/charging/pile/operation/report/order/seven`,
      method:'post',
      data:data,
    })
  }
  // 经营报表统计
  export function operationStatistics(data={}){
    return axios.request({
      url:`admin/charging/pile/operation/report/statistics`,
      method:'post',
      data:data,
    })
  }
  // 微信商户营收数据
  export function getMerchantAmount(data={}){
    return axios.request({
      url:`admin/charging/pile/operation/report/merchant/amount`,
      method:'post',
      data:data,
    })
  }
  // 创建全小区经营报表
  export function createOperationReport(data={}){
    return axios.request({
      url:`admin/charging/pile/operation/report/create`,
      method:'post',
      data:data,
    })
  }
  // 分页查询经营报表统计记录
  export function getOperationReport(data={}){
    return axios.request({
      url:`admin/charging/pile/operation/report/page`,
      method:'post',
      data:data,
    })
  }
  // 查询经营报表详情
  export function getOperationReportInfo(data={}){
    return axios.request({
      url:`admin/charging/pile/operation/report/list/info`,
      method:'post',
      data:data,
    })
  }
  // 查询日经营报表详情
  export function getOperationDailyReportInfo(data={}){
    return axios.request({
      url:`admin/charging/pile/operation/report/list/infoDay`,
      method:'post',
      data:data,
    })
  }


  // 车载设备管理
  // 新增设备
  export function addCarDevice(data={}){
    return axios.request({
      url:`admin/ebike/device/create`,
      method:'post',
      data:data,
    })
  }
  // 编辑设备
  export function updateCarDevice(data={}){
    return axios.request({
      url:`admin/ebike/device/update`,
      method:'post',
      data:data,
    })
  }
  // 删除设备
  export function delCarDevice(data={}){
    return axios.request({
      url:`admin/ebike/device/deleted`,
      method:'post',
      data:data,
    })
  }
  // 查询设备列表
  export function getCarDeviceList(data={}){
    return axios.request({
      url:`admin/ebike/device/page`,
      method:'post',
      data:data,
    })
  }

  


  
  export function testOrder(data={}){
    return axios.request({
      url:`https://www.ledianxinnengyuan.com/OrderInfo/GetData`,
      method:'post',
      data:data,
    })
  }


