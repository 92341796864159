import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import './theme/element/index.css'
import '@/assets/iconfont/iconfont.css'
import Plugin from 'v-fit-columns'
import AFTableColumn from 'af-table-column'
import JSEncrypt from "jsencrypt"; //引入模块
import VueCookies from 'vue-cookies';
import dataV from '@jiaminghi/data-view'
// import XLSXS from 'xlsx-js-style';
import 'amfe-flexible'; // 引入amfe-flexible做rem适配

function px2rem(px){
  if(/%/ig.test(px)){ // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px
  }else{
    return (parseFloat(px) / 192) + 'rem'
  }
}

Vue.prototype.$px2rem = px2rem // 放到全局

Vue.use(dataV)
// Vue.use(XLSXS)
Vue.use(VueCookies)
Vue.prototype.$jsEncrypt = JSEncrypt; //配置全局变量
Vue.use(AFTableColumn)

Vue.use(Element);
Vue.use(Plugin);

Vue.config.productionTip = false;
Vue.use(Element);
Vue.prototype.$bus = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
