<template>
  <div class="login">
    <h3>监测系统</h3>
    <div class="account">
      <el-input
        placeholder="商户代码"
        prefix-icon="el-icon-user"
        v-model="params.merchantCode"
        class="input"
        @keypress.native.enter="onEnterPress"
      ></el-input>
      <el-input
        placeholder="账号"
        prefix-icon="el-icon-user"
        v-model="params.accountName"
        class="input"
        @keypress.native.enter="onEnterPress"
      ></el-input>
    </div>
    <el-input
      placeholder="密码"
      prefix-icon="el-icon-lock"
      v-model="params.password"
      class="input"
      @keypress.native.enter="onEnterPress"
      show-password
    ></el-input>
    <el-button class="btn" @click="getKey">登录</el-button>
    <p>
      Copyright &copy; 杭州乐电新能源有限公司出品
      <a
        style="color: blue"
        href="https://beian.miit.gov.cn"
        target="_blank"
        rel="noopener noreferrer"
      >
        浙ICP备2024127825号
      </a>
    </p>
  </div>
</template>

<script>
import {
  login,
  getRSAPublicKey,
  getUserInfo,
  getMerchantDetail,
} from '@/api/public.js'
export default {
  data() {
    return {
      params: {
        loginName: '',
        password: '',
      },
    }
  },
  mounted() {
    if (this.$route.query.token) {
      sessionStorage.setItem('token', this.$route.query.token)
      this.$emit('goHome', true)
    }
  },

  components: {},

  computed: {},

  methods: {
    // 回车键登录
    onEnterPress(e) {
      if (e.keyCode === 13) {
        this.getKey()
      }
    },
    // 获取公钥
    getKey() {
      getRSAPublicKey({}).then(res => {
        // getPubKey---获取公钥接口
        if (res.success) {
          const jse = new this.$jsEncrypt() // 实例化一个 jsEncrypt 对象
          jse.setPublicKey(res.data) // 配置公钥
          let account =
            this.params.merchantCode != '' && this.params.merchantCode
              ? `${this.params.merchantCode}-${this.params.accountName}`
              : this.params.accountName
          let userAccount = jse.encrypt(account) // 加密账号
          let passWord = jse.encrypt(this.params.password) // 加密密码
          let loginData = {
            accountCipertext: userAccount, // 账号
            identifyingCode: '',
            publicKeyBase64: res.data, // 公钥
            pwdCiphertext: passWord, // 密码
          }
          login({ param: loginData }).then(loginRes => {
            if (loginRes.success && loginRes.code == 200) {
              // 登录成功后的操作
              sessionStorage.setItem('token', loginRes.data)
              this.getUser()
            } else {
              // 登录失败操作
              this.$message({
                showClose: true,
                message: loginRes.msg,
                type: 'error',
              })
              sessionStorage.setItem('login', false)
              this.$bus.$emit('isLogin', false)
            }
          })
        }
      })
    },
    async getUser() {
      await getUserInfo().then(res => {
        if (res.success) {
          if (res.data.roleLevel >= 5) {
            this.getMerchantInfo(res.data)
          } else {
            localStorage.setItem('userInfo', JSON.stringify(res.data))
            this.$router.push('/home')
          }
        }
      })
    },
    getMerchantInfo(userInfo) {
      getMerchantDetail({ param: userInfo.merchantId }).then(res => {
        if (res.success) {
          userInfo.merchantInfo = res.data
          localStorage.setItem('userInfo', JSON.stringify(userInfo))
          this.$router.push('/home')
        }
      })
    },
    // 点击登录按钮登录
    login() {
      getRSAPublicKey({ userName: this.params.loginName, userType: 1 }).then(
        res => {
          if (res.success) {
            login(this.params).then(res => {
              if (res.Success) {
                this.$emit('goHome', true)
                sessionStorage.setItem('roleId', res.Data.user.RoleId)
                sessionStorage.setItem('token', res.Data.token)
                sessionStorage.setItem('UserName', res.Data.user.UserName)
              } else {
                this.$message({
                  showClose: true,
                  message: res.Message,
                  type: 'error',
                })
              }
            })
          }
        }
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.account {
  display: flex;
  width: 60%;
  .input {
    width: 50%;
  }
}
.login {
  background: #001529;
  width: 100vw;
  height: 100vh;
  // padding:100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  h3 {
    margin-top: 5%;
    font-size: 36px;
    color: white;
    font-weight: bolder;
    letter-spacing: 6px;
    margin-bottom: 20px;
  }
  .input {
    width: 60%;
    margin-top: 30px;
    display: block;
  }
  .btn {
    background: #1890ff;
    width: 60%;
    margin-top: 30px;
    color: #fff;
  }
  p {
    position: fixed;
    bottom: 20px;
    font-size: 14px;
    color: #ccc;
    padding: 0 10px;
  }
}
</style>
