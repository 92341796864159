<template>
  <el-menu
   :collapse="false" 
   collapse-transition router 
   :default-active="activePath"
   unique-opened
   class="el-menu-vertical-demo"
   background-color="#001529"
   text-color="#fff" 
   active-text-color="#ffd04b"
   @select="selectMenu">
    <div v-for="menu in allmenu" :key="menu.id">
      <el-submenu :index="menu.link" v-if="menu.childrenList.length > 0" class="nav-col">
        <template slot="title">
          <i :class="menu.icon"></i>
          <span>{{menu.name}}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-for="chmenu in menu.childrenList" :index="chmenu.link" :key="chmenu.name" class="nav-col">
            <span>{{chmenu.name}}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item :index="menu.link" class="nav-col" v-else>
        <i :class="menu.icon"></i>
        <span slot="title">{{menu.name}}</span>
      </el-menu-item>
    </div>
  </el-menu>
</template>
<script>
import { selectMenuByRoleId } from '@/api/public.js';
export default {
  name: 'leftnav',
  props:['isLogin'],
  data() {
    return {
      allmenu:[],
      user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
      activePath:'',
      getMenu:sessionStorage.getItem('login'),
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
  },
  watch:{
    isLogin: {
      deep: true,
      handler (val, oldVal) {
        this.user = JSON.parse(localStorage.getItem('userInfo'));
      }
    }
  },
  mounted(){
    this.getMenuList();
  },
  methods:{
    async getMenuList(){
      this.user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      let id = this.user ? this.user.roleId : -1;
      await selectMenuByRoleId({param:id}).then(res => {
        if(res.success){
          this.allmenu = res.data;
          this.$nextTick(()=>{
            if(localStorage.getItem('activePath')){
              this.activePath = localStorage.getItem('activePath');
            }else{
              this.activePath = this.allmenu[0].childrenList && this.allmenu[0].childrenList.length > 0 ? this.allmenu[0].childrenList[0].link : this.allmenu[0].link;
              localStorage.setItem('activePath',this.activePath);
            }
          })
        }
      })
    },
    // 菜单栏被激活回调
    selectMenu(e){
      localStorage.setItem('activePath',e)
      for(let i=0;i < this.allmenu.length;i++){
        if(e == this.allmenu[i].link){
          this.$emit('handleMenuActive',this.allmenu[i]);
          break;
        }else{
          let child = this.allmenu[i].childrenList;
          if(child.length > 0){
            for(let k=0;k<child.length;k++){
              if(e == child[k].link){
                this.$emit('handleMenuActive',this.allmenu[i],child[k]);
                break;
              }
            }
          }
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/static/default";
.nav-col:hover {
  background-color:rgba(24, 144, 255, 0.9) !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}
.el-menu-item-group__title {
  padding: 0px;
}
.el-menu-bg {
  background-color: #1f2d3d !important;
}
.el-menu {
  border: none;
}
</style>